/**
 * 全体アーカイブのテンプレート(XDのpages2)
 */
import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '@/templates/Layout';
import ArchiveHeading from '@/atoms/ArchiveHeading';
import ArticleCardsList from '@/organisms/ArticleCardsList';
import Sidebar from '@/organisms/Sidebar';
import Pagination from '@/organisms/Pagination';
import Seo from '@/components/Seo';
import { ArchivePageContext } from '@/types/pageContext';
import { Content } from '@/templates/top';

const ArchivePage: React.FC<PageProps<GatsbyTypes.ArchivePageQuery>> = ({
  data,
  pageContext,
  location,
}) => {
  const posts = data.allMarkdownRemark.nodes as GatsbyTypes.MarkdownRemark[];
  const siteTags = data.allSiteTag.nodes as GatsbyTypes.SiteTag[];
  const { pageCount, currentPage } = pageContext as ArchivePageContext;
  const { pathname, href, origin } = location;

  return (
    <>
      <Seo url={href || `/${currentPage}/`} origin={origin} />
      <Layout>
        <ArchiveHeading>記事一覧</ArchiveHeading>
        <Content>
          <div>
            <ArticleCardsList posts={posts} siteTags={siteTags} />
            <Pagination
              slug=""
              pageCount={pageCount}
              currentPage={currentPage}
            />
          </div>
          <Sidebar pathname={pathname} />
        </Content>
      </Layout>
    </>
  );
};

export const query = graphql`
  query ArchivePage($limit: Int, $skip: Int) {
    allMarkdownRemark(
      filter: { fields: { itemType: { eq: "article" } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt(truncate: true, format: PLAIN, pruneLength: 100)
        fields {
          slug
        }
        frontmatter {
          title
          tags
          date
          image_public_url
          image {
            publicURL
          }
        }
      }
    }
    allSiteTag {
      nodes {
        slug
        title
        is_pickup
      }
    }
  }
`;

export default ArchivePage;
